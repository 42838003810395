span {
	filter: invert(0%);
}

table.table td i {
	font-size: 19px;
}

.hint-text {
	float: inline-start;
	margin-block-start: 10px;
	font-size: 13px;
}

/* Modal styles */
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
	padding: 20px 30px;
}
.modal .modal-content {
	border-radius: 3px;
	font-size: 14px;
}
.modal .modal-footer {
	background: #ecf0f1;
	border-radius: 0 0 3px 3px;
}
.modal .modal-title {
	display: inline-block;
}
.modal .form-control {
	border-radius: 2px;
	box-shadow: none;
	border-color: black;
}
.modal textarea.form-control {
	resize: vertical;
}
.modal .btn {
	border-radius: 2px;
	min-inline-size: 100px;
}
.modal form label {
	font-weight: normal;
}

#adminHomeContainer {
	margin-block-end: 50px;
	padding: 0px;
	block-size: 100vh;
}

#TableSorterCard {
	border-style: none;
	inline-size: 100%;
	background: #f6f6f6;
	border-radius: 0px;
}

.card-header {
	background: #003d9c;
}

.thead-dark {
	background: #f6f6f6;
	border-width: 0px;
	border-color: rgb(0, 0, 0);
	border-block-end-color: #003d9c;
}

tr {
	border-style: none;
	border-color: rgba(255, 255, 255, 0);
	background: #f6f6f6;
	border-color: black;
}

td {
	color: black;
	max-block-size: 60px;
	block-size: 60px;
}

.btnMaterial.btn-flat.success.semicircle {
	color: rgb(0, 197, 179);
}

.btnMaterial.btn-flat.accent.btnNoBorders.checkboxHover {
	margin-inline-start: 5px;
}

.m-0 {
	color: rgb(255, 255, 255);
}
.fas.fa-trash.btnNoBorders {
	color: #dc3545;
}

.btnMaterial.btn-flat.success.semicircle {
	color: rgb(0, 197, 179) !important;
}

.row {
	margin-inline-end: 0px;
}

.col-12 {
	padding-inline-end: 0px;
}

#cardHeader {
	border-radius: 0px;
}
