#a {
	color: white !important;
}

.clean-navbar.scrolled {
	transition: 200ms;
	background-color: #003d9c !important;
}

h1 {
	font-weight: bold;
}

h2 {
	margin: 0px;
	font-weight: bold;
}

span {
	filter: invert(100%);
}

.navbar-nav-scroll {
	margin: 0px;
	text-align: center;
}

.clean-block.clean-hero {
	opacity: 1;
	background: url("../../../public/assets/img/mainBackground.png");
	min-height: 400px;
}

.page.landing-page {
	padding-top: 0px !important;
}

.text {
	font-weight: bold;
}

.clean-block.slider.dark {
	border-style: none;
	padding-bottom: 60px;
}

/* .container   {
	width: 100%;
	height: 100%;
} */

.block-heading {
	margin: 0px;
	padding: 40px;
}

#imageCover {
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
	opacity: 1;
	filter: brightness(77%);
}

#carouselImg {
	min-width: 100%;
	max-width: 50px !important;
	max-height: 75vh !important;
	filter: brightness(60%);
}

#carousel-2 {
	padding-bottom: 60px;
}

.projects {
	padding: 20px;
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.carousel-control-prev {
	width: 4.5%;
}

.carousel-control-next {
	width: 4.5%;
}

.feature-box {
	min-width: 50%;
	max-width: 200px !important;
	width: auto;
}
.feature-box.h4 {
	min-width: 50%;
	max-width: 200px;
}

.carousel-control-prev,
.carousel-control-next {
	color: black !important;
	opacity: 100;
}

#projectsCarouselControl {
	filter: invert(100%) !important;
	color: black !important;
	opacity: 100;
}
