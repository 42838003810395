span {
	filter: invert(0%);
}

.Resizer {
	position: relative;
	background: darkGray;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}
.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	block-size: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	inline-size: 1px;
}

.Resizer.horizontal {
	block-size: 11px;
	margin: -5px 0;
	border-block-start: 5px solid rgba(255, 255, 255, 0);
	border-block-end: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	inline-size: 100%;
}

.Resizer.vertical {
	inline-size: 20px;
	margin: 0 -5px;
	border-inline-start: 5px solid rgba(255, 255, 255, 0);
	border-inline-end: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.disabled {
	cursor: not-allowed;
}
.Resizer.disabled:hover,
.Resizer.disabled:active {
	border-color: transparent;
}

.Resizer::after,
.Resizer::before {
	content: "";
	border-inline-start: 1px solid #333;
	position: absolute;
	inset-block-start: 50%;
	transform: translateY(-100%);
	inset-inline-end: 0;
	display: inline-block;
	block-size: 20px;
	margin: 0 2px;
}
.Resizer::before {
	inset-inline-start: 0;
}

#tinyMCE {
	block-size: 100vh;
}

.modal .modal-footer {
	justify-content: center;
}

#removeImageButton {
	display: inline-block;
	margin-block-start: 2%;
	block-size: 5%;
	font-size: 13px;
	color: black;
}

#resumeModal {
	width: 100vw;
}
