#indexNavbar {
	--bs-primary-rgb: 13, 110, 253;
	background: #003d9c;
}

h2 {
	margin-top: 20px;
	margin-bottom: 20px;
}
.image-container {
	width: 100%; /* Full width */
	aspect-ratio: 1 / 1; /* Fixed aspect ratio */
	overflow: hidden; /* Hide the excess of the image */
}

#person-image {
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	object-fit: cover;
}

.card.text-center.clean-card {
	width: 100%; /* Take up the full width of the parent */
	max-width: 350px; /* Maximum width */
	min-height: 450px; /* Minimum height */
}

.d-flex {
	display: flex;
}

.justify-content-between {
	justify-content: center;
}

.card-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

#cardTextLattes {
	font-size: 0.9rem;
}

.info {
	padding-top: 0px !important;
	padding-bottom: 16px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
}